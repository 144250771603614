<template>
  <v-card
    flat
    :style="{
      borderRadius: '5px 5px 30px 30px',
      borderTop: `10px solid ${color}`,
      overflow: 'auto',
      color: text_color,
    }"
    height="166"
    class="pa-6 d-flex align-center justify-center"
  >
    {{ text }}
  </v-card>
</template>

<script>
export default {
  props: {
    text: null,
    text_color: null,
    color: null,
  },
};
</script>

<style></style>
